import page1Title from './page1Title.vue'
import page1Desc from './page1Desc.vue'
import commonItalic from './commonItalic.vue'
import commonBold from './commonBold.vue'
const allComponents = {
  page1Title,
  page1Desc,
  commonItalic,
  commonBold,
}
export default{
    install(Vue){
        console.log(Vue);
        Object.keys(allComponents).forEach(key=>{
            Vue.component(key,allComponents[key])
            console.log(`Registered component: ${key}`);
        })
    }
}