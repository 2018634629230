import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/page6/index.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/page1',
    name: 'page1',
    component: () => import('../views/page1/index.vue')
  },
  {
    path: '/page2',
    name: 'page2',
    component: () => import('../views/page2/index.vue')
  },
  {
    path: '/page3',
    name: 'page3',
    component: () =>import('../views/page3/index.vue')
  },
  {
    path: '/page4',
    name: 'page4',
    component: () =>import('../views/page4/index.vue')
  },
  {
    path: '/page5',
    name: 'page5',
    component: () =>import('../views/page5/index.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
