
<template>
  <div class="cor-00172E fz-18  fw-bold " :class="customClass"> {{ title }}</div>
</template>
<script>
export default {
  name:'page1Title',
  props:{
    title:{
      type: String,
      default: '>>> Xem thêm:'
    },
    customClass:{
      type: String,
      default: 'Cách đào Bitcoin, cách tính lợi nhuận khi đào Bitcoin'
    },
  },
  components:{},
  data() {
    return {}
  },
  methods: {},
  mounted() {}
}
</script>
<style scoped>

</style>