<template>
  <div class=" cor-404040 fz-18 w-full" :class="customClass">
      {{title1}}<span class="fw-bold text-italic" style="text-decoration: underline;">{{ title2 }}</span>
    </div>
</template>
<script>
export default {
  name:'page1Desc',
  props:{
    title1:{
      type: String,
      default: ' >>> Xem thêm:'
    },
    title2:{
      type: String,
      default: 'Những đồng coin có tiềm năng [ THÔNG TIN CẦN BIẾT ]'
    },
    customClass:{
      type: String,
      default: 'mt-17'
    },
  },
  components:{},
  data() {
    return {}
  },
  methods: {},
  mounted() {}
}
</script>
<style scoped>

</style>