<template>
  <div class="text-italic" :class="customClass">
      <slot></slot>
    </div>
</template>
<script>
export default {
  name:'commonItalic',
  props:{
    title:{
      type: String,
      default: ''
    },
    customClass:{
      type: String,
      default: ''
    },
  },
}
</script>
