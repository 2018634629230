<template>
  <div class="app">
    <!-- <div>
      <router-link :to="{ name: 'page1' }">page1</router-link>||
      <router-link :to="{ name: 'page2' }">page2</router-link>||
      <router-link :to="{ name: 'page3' }">page3</router-link>||
      <router-link :to="{ name: 'page4' }">page4</router-link>||
      <router-link :to="{ name: 'page5' }">page5</router-link>||
    </div> -->
    <router-view />
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped></style>
