<template>
  <div class="fw-bold" :class="customClass">
    <slot></slot>
    </div>
</template>
<script>
export default {
  name:'commonBold',
  props:{
    title:{
      type: String,
      default: ''
    },
    customClass:{
      type: String,
      default: ''
    },
  },
}
</script>
